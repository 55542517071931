// src/pages/Alerts.jsx



import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import { Helmet } from 'react-helmet';
import useAlertData from '../hooks/useAlertData';
import useTopCities from '../hooks/useTopCities';
import { countReportsByLocation } from '../utils/geoUtils';
import {
    RootContainer,
    HeaderPaper,
    MapSection,
    TerminalText,
    TopCitiesSection,
    StyledPagination,
    StyledButton
} from '../styles/AlertPageStyles';



  

const AlertPage = () => {
  const alerts = useAlertData();
  const [topLocations, setTopLocations] = useState([]);
  const topCities = useTopCities();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const alertsPerPage = 10;
  const totalPages = Math.ceil(alerts.length / alertsPerPage);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const displayedAlerts = alerts.slice(
    (page - 1) * alertsPerPage,
    page * alertsPerPage
  );


  useEffect(() => {
    const fetchLocations = async () => {
      // Use the reusable countReportsByLocation function
      const sortedLocations = await countReportsByLocation(alerts);
    //   console.log("Top Locations:", sortedLocations); // Debugging logs
      setTopLocations(sortedLocations); // Update the state with results
    };
  
    if (alerts.length > 0) {
      fetchLocations(); // Process only when alerts are available
    }
  }, [alerts]); // Recalculate when alerts change
  
  
  

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    name: "Cybercrime Alerts",
    description: "A list of recent cybercrime alerts reported through CCRNet.",
    itemListElement: alerts.map((alert, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@type": "Article",
        headline: alert.title,
        description: alert.description,
        datePublished: alert.date,
        articleSection: "Cybercrime Alerts",
        author: {
          "@type": "Organization",
          name: "CCRNet",
        },
      },
    })),
  };

  const adjustPositionToNearestContinent = (position) => {
    const continents = [
      { name: "Africa", coords: [0, 25] },
      { name: "Europe", coords: [50, 10] },
      { name: "Asia", coords: [35, 105] },
      { name: "North America", coords: [45, -100] },
      { name: "South America", coords: [-15, -60] },
      { name: "Australia", coords: [-25, 135] },
      { name: "Antarctica", coords: [-90, 0] },
    ];

    let nearest = continents[0];
    let minDistance = Number.MAX_VALUE;

    continents.forEach((continent) => {
      const distance = Math.sqrt(
        Math.pow(position[0] - continent.coords[0], 2) +
          Math.pow(position[1] - continent.coords[1], 2)
      );
      if (distance < minDistance) {
        minDistance = distance;
        nearest = continent;
      }
    });

    // Random offset for more spread
    const randomDistance = Math.random() * 10 + 1; // Random distance between 1 and 10 degrees
    const randomAngle = Math.random() * 2 * Math.PI; // Random direction (0-360 degrees)

    const latOffset = randomDistance * Math.cos(randomAngle); // Latitude adjustment
    const lonOffset = randomDistance * Math.sin(randomAngle); // Longitude adjustment

    return [nearest.coords[0] + latOffset, nearest.coords[1] + lonOffset];
  };



  return (
    <RootContainer>
      <Helmet>
        <title>Cybercrime Alerts - Stay Informed | CCRNet</title>
        <meta
          name="description"
          content="Stay informed with the latest cybercrime alerts worldwide. Explore real-time reports, top affected countries, and detailed insights into cyber threats from CCRNet."
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Box mb={2} display="flex" justifyContent="flex-start">
        <StyledButton onClick={() => navigate("/")}>
          {" "}
          &larr; Back to Home{" "}
        </StyledButton>
      </Box>

      <HeaderPaper>
        <Typography variant="h3" component="h1" gutterBottom>
          {" "}
          Cybercrime Alerts{" "}
        </Typography>
        <Typography variant="h6" component="p">
          {" "}
          Stay informed with the latest cybercrime alerts and safety
          information.{" "}
        </Typography>
      </HeaderPaper>

      <MapSection>
        <MapContainer
          center={[20, 0]}
          zoom={2}
          style={{ height: "100%", width: "100%" }}
          scrollWheelZoom={false} // Disable zoom with scroll wheel
          doubleClickZoom={false} // Disable zoom on double-click
          dragging={false} // Disable dragging
          zoomControl={false} // Disable zoom control buttons (+ / -)
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.hiveforensics.com">Hive Forensics</a>'
          />
          {alerts.map((alert) => (
            <Circle
              key={alert.id}
              center={adjustPositionToNearestContinent(alert.position)}
              radius={200000}
              fillColor="#4caf50"
              color="#4caf50"
              weight={1}
              fillOpacity={0.6}
            >
              <Popup>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ color: "#4caf50" }}
                >
                  {alert.title}
                </Typography>
                <Typography variant="body2" style={{ color: "#bdbdbd" }}>
                  {alert.date}
                </Typography>
                <Typography variant="body1" style={{ color: "#e0e0e0" }}>
                  {alert.description}
                </Typography>
              </Popup>
            </Circle>
          ))}
        </MapContainer>
      </MapSection>

      <TopCitiesSection>
        <Typography variant="h4" component="h2" gutterBottom>
          Top 5 Locations with Most Reports
        </Typography>
        {topLocations.length > 0 ? (
          topLocations.map((locationData, index) => (
            <TerminalText key={index} variant="body1" gutterBottom>
              {`${index + 1}. ${locationData.location}: ${
                locationData.count
              } reports`}
            </TerminalText>
          ))
        ) : (
          <Typography variant="body1">No reports available.</Typography>
        )}
      </TopCitiesSection>

      <Box>
        {displayedAlerts.map((alert) => (
          <TerminalText key={alert.id} variant="body1" gutterBottom>
            {`[${alert.date}] ${alert.title}\n${alert.description}`}
          </TerminalText>
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        mt={4}
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "#121212",
          padding: "16px",
          zIndex: 1000,
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.5)",
        }}
      >
        <StyledPagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </RootContainer>
  );
};

export default AlertPage;





// import React, { useState } from 'react';
// import { Typography, Box } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
// import { Helmet } from 'react-helmet';
// import useAlertData from '../hooks/useAlertData';
// import useTopCities from '../hooks/useTopCities';
// import {
//     RootContainer,
//     HeaderPaper,
//     MapSection,
//     TerminalText,
//     TopCitiesSection,
//     StyledPagination,
//     StyledButton
// } from '../styles/AlertPageStyles';

// const AlertPage = () => {
//     const alerts = useAlertData();
//     const topCities = useTopCities();
//     const navigate = useNavigate();

//     const [page, setPage] = useState(1);
//     const alertsPerPage = 10;
//     const totalPages = Math.ceil(alerts.length / alertsPerPage);

//     const handleChangePage = (event, value) => {
//         setPage(value);
//     };

//     const displayedAlerts = alerts.slice(
//         (page - 1) * alertsPerPage,
//         page * alertsPerPage
//     );

//     // Construct structured data (JSON-LD) for SEO
//     const structuredData = {
//         "@context": "https://schema.org",
//         "@type": "ItemList",
//         "name": "Cybercrime Alerts",
//         "description": "A list of recent cybercrime alerts reported through CCRNet.",
//         "itemListElement": alerts.map((alert, index) => ({
//             "@type": "ListItem",
//             "position": index + 1,
//             "item": {
//                 "@type": "Article",
//                 "headline": alert.title,
//                 "description": alert.description,
//                 "datePublished": alert.date,
//                 "articleSection": "Cybercrime Alerts",
//                 "author": {
//                     "@type": "Organization",
//                     "name": "CCRNet"
//                 }
//             }
//         }))
//     };

//     return (
//         <RootContainer>
//             <Helmet>
//                 <title>Cybercrime Alerts - Stay Informed | CCRNet</title>
//                 <meta
//                     name="description"
//                     content="Stay informed with the latest cybercrime alerts worldwide. Explore real-time reports, top affected countries, and detailed insights into cyber threats from CCRNet."
//                 />
//                 <script type="application/ld+json">
//                     {JSON.stringify(structuredData)}
//                 </script>
//             </Helmet>

//             {/* Back to Home Button */}
//             <Box mb={2} display="flex" justifyContent="flex-start">
//                 <StyledButton onClick={() => navigate('/')}>
//                     &larr; Back to Home
//                 </StyledButton>
//             </Box>

//             {/* Header */}
//             <HeaderPaper>
//                 <Typography variant="h3" component="h1" gutterBottom>
//                     Cybercrime Alerts
//                 </Typography>
//                 <Typography variant="h6" component="p">
//                     Stay informed with the latest cybercrime alerts and safety information.
//                 </Typography>
//             </HeaderPaper>

//             {/* Global Map */}
//             <MapSection>
//                 <MapContainer center={[20, 0]} zoom={2} style={{ height: '100%', width: '100%' }}>
//                     <TileLayer
//                         url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
//                         attribution='&copy; <a href="https://www.hiveforensics.com">Hive Forensics</a>'
//                     />
//                     {alerts.map((alert) => (
//                         <Circle
//                             key={alert.id}
//                             center={alert.position}
//                             radius={200000}
//                             fillColor="#4caf50"
//                             color="#4caf50"
//                             weight={1}
//                             fillOpacity={0.6}
//                         >
//                             <Popup>
//                                 <Typography variant="h6" component="h2" style={{ color: '#4caf50' }}>
//                                     {alert.title}
//                                 </Typography>
//                                 <Typography variant="body2" style={{ color: '#bdbdbd' }}>
//                                     {alert.date}
//                                 </Typography>
//                                 <Typography variant="body1" style={{ color: '#e0e0e0' }}>
//                                     {alert.description}
//                                 </Typography>
//                             </Popup>
//                         </Circle>
//                     ))}
//                 </MapContainer>
//             </MapSection>

//             {/* Top 5 Cities */}
//             <TopCitiesSection>
//                 <Typography variant="h4" component="h2" gutterBottom>
//                     Top 5 Countries with Most Reports
//                 </Typography>
//                 {topCities.map((cityData, index) => (
//                     <TerminalText key={index} variant="body1" gutterBottom>
//                         {`${index + 1}. ${cityData.city}: ${cityData.count} reports`}
//                     </TerminalText>
//                 ))}
//             </TopCitiesSection>

//             {/* Alerts List */}
//             <Box>
//                 {displayedAlerts.map((alert) => (
//                     <TerminalText key={alert.id} variant="body1" gutterBottom>
//                         {`[${alert.date}] ${alert.title}\n${alert.description}`}
//                     </TerminalText>
//                 ))}
//             </Box>

//             {/* Pagination Controls */}
//             <Box
//                 display="flex"
//                 justifyContent="center"
//                 mt={4}
//                 sx={{
//                     position: 'sticky',
//                     bottom: 0,
//                     backgroundColor: '#121212',
//                     padding: '16px',
//                     zIndex: 1000,
//                     boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.5)',
//                 }}
//             >
//                 <StyledPagination
//                     count={totalPages}
//                     page={page}
//                     onChange={handleChangePage}
//                     color="primary"
//                 />
//             </Box>
//         </RootContainer>
//     );
// };

// export default AlertPage;