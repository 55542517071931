// src/utils/geoUtils.js



import { getDistance } from 'geolib';

// Cache and Storage Configuration
const LOCAL_TOP_LOCATIONS = 'topLocationsCache';
const CACHE_EXPIRATION_HOURS = 24;

// Delay Helper
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

// Save to LocalStorage
const saveToLocalStorage = (key, data) => {
  const cacheData = { data, timestamp: Date.now() };
  localStorage.setItem(key, JSON.stringify(cacheData));
};

// Load from LocalStorage with Expiry Check
const loadFromLocalStorage = (key) => {
  const cache = localStorage.getItem(key);
  if (!cache) return null;

  const { data, timestamp } = JSON.parse(cache);
  const ageHours = (Date.now() - timestamp) / (1000 * 60 * 60);
  return ageHours < CACHE_EXPIRATION_HOURS ? data : null;
};

// Approximate Continent Mapping
export const approximateContinent = (latitude, longitude) => {
  const continents = [
    { name: 'Africa', coords: [0, 25] },
    { name: 'Europe', coords: [50, 10] },
    { name: 'Asia', coords: [35, 105] },
    { name: 'North America', coords: [45, -100] },
    { name: 'South America', coords: [-15, -60] },
    { name: 'Australia', coords: [-25, 135] },
    { name: 'Russia', coords: [-90, 0] },
  ];

  let nearest = continents[0];
  let minDistance = Number.MAX_VALUE;

  continents.forEach((continent) => {
    const distance = getDistance(
      { latitude, longitude }, // Current position
      { latitude: continent.coords[0], longitude: continent.coords[1] } // Continent center
    );
    if (distance < minDistance) {
      minDistance = distance;
      nearest = continent;
    }
  });

  console.log(`Mapped to continent: ${nearest.name}`);
  return nearest.name;
};

// Count Reports by Location (Using Approximate Continents)
export const countReportsByLocation = async (alerts) => {
  console.log('Counting reports by location...');

  // Check Local Cache First
  const cachedData = loadFromLocalStorage(LOCAL_TOP_LOCATIONS);
  if (cachedData) {
    console.log('Using cached top locations:', cachedData);
    return cachedData;
  }

  const locationCounts = {};

  for (const alert of alerts) {
    let location = alert.incidentLocation?.trim();

    // Map to Continent if No Specific Location is Provided
    if (!location && alert.position && alert.position.length === 2) {
      const [latitude, longitude] = alert.position;
      console.log(`Processing alert at ${latitude}, ${longitude}`);

      location = approximateContinent(latitude, longitude);
    }

    location = location || 'Unspecified';
    locationCounts[location] = (locationCounts[location] || 0) + 1;
    console.log(`Location count for ${location}: ${locationCounts[location]}`);
  }

  // Find Top 5 Locations
  const topLocations = Object.entries(locationCounts)
    .map(([location, count]) => ({ location, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  console.log('Top locations:', topLocations);
  saveToLocalStorage(LOCAL_TOP_LOCATIONS, topLocations);
  return topLocations;
};
